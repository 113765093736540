import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import '@/styles/tailwind.css'
import styled from 'styled-components'
import tw from 'twin.macro'

import { ReactComponent as LogoAndName } from '@/images/logo-and-name.svg'
import { ReactComponent as Twitter } from '@/images/twitter.svg'
import { ReactComponent as LinkedIn } from '@/images/linkedin.svg'

const contactEmail = 'info@jumptrading.com'
const mediaEmail = 'mediainquiries@jumptrading.com'
export const locations = [
  'Chicago',
  'New York',
  'Austin',
  'London',
  'Singapore',
  'Shanghai',
  'Bristol',
  'Mumbai',
  'Sydney',
  'Amsterdam',
  'GIFT City',
  'Hong Kong',
  'Paris',
]

export const CenteredResponsiveContainer = tw.div`max-w-7xl px-2 md:px-4 py-4 mx-auto md:space-x-10 lg:px-8`

export const Heading = styled.div`
  ${tw`text-sm font-medium h-8 text-dark-gray tracking-wider uppercase`}
  letter-spacing: 2px;
  line-height: 30px;
`

export const UnderlinedTextLink = tw.div`h-7 w-max pb-1 mb-3 border-b-black border-b border-dashed hover:border-solid hover:border-b-2 hover:border-b-jump-red`
const PrivacyPolicyLink = tw(UnderlinedTextLink)`block border-dark-gray mb-0` // no margin bottom
const InlineLink = tw.span`h-7 w-max pb-1 mb-3 border-b-black border-b border-dashed hover:border-solid hover:border-b-2 hover:border-b-jump-red`
const OfficialAccountsLink = tw(InlineLink)`border-dark-gray mb-0`

const SocialButton = tw.a`block rounded-full w-9 h-9 hover:bg-jump-red border border-black hover:border-jump-red`

const mssFilename = 'JTIL_Modern-Slavery-Statement-FYE-2023.pdf'

export const Layout: React.FC<{}> = ({ children, ...props }) => {
  const { site } = useStaticQuery<LayoutQueryType>(LayoutQuery)
  const [cookieAgreement, setCookieAgreement] = useState<boolean>()
  useEffect(() => {
    setCookieAgreement(Boolean(localStorage.getItem('cookiesOk')))
  }, [])
  const cookiesOk = () => {
    window.localStorage.setItem('cookiesOk', 'true')
    setCookieAgreement(true)
  }
  return (
    <div {...props} className="bg-background flex flex-col min-h-screen">
      <header>
        <div className=" mt-0 py-1 px-1 bg-jump-red-dark">
          <div
            className=" max-w-7xl px-1 py-1 mx-auto grid  md:gap-1
          md:grid-cols-[auto_auto]  justify-center items-center
          text-white  text-xs sm:text-sm md:text-sm lg:text-base "
          >
            <div>Beware of scams impersonating Jump Trading Group.</div>
            <div>
              We only communicate through our&nbsp;
              <OfficialAccountsLink>
                <Link to="/communication/">official accounts</Link>.
              </OfficialAccountsLink>
            </div>
          </div>
        </div>
        <CenteredResponsiveContainer>
          <div className="flex justify-between items-center h-19 lg:pt-4">
            <Link to="/" className="block">
              <span className="sr-only">Jump Trading Logo</span>
              <LogoAndName className="h-auto w-19" />
            </Link>
            <div className="bg-jump-red rounded-lg">
              <Link
                to="/careers/"
                className="inline-flex items-center justify-center px-4 py-3
                    text-sm font-medium text-background uppercase w-36 relative z-20"
              >
                Careers
              </Link>
            </div>
          </div>
        </CenteredResponsiveContainer>
      </header>

      <main className="ml-0 flex-grow">{children}</main>

      <footer>
        <CenteredResponsiveContainer>
          <div className="mt-10">
            <div className="flex justify-start align-top flex-col mb-8">
              <span className="sr-only">Jump Trading Logo</span>
              <LogoAndName className="h-auto w-19 mb-2" />
            </div>

            <div
              className="grid gap-x-20 gap-y-12  grid-cols-1 md:grid-cols-2
            lg:grid-cols-[auto_auto_1fr] justify-start"
            >
              <div className="flex flex-col align-top gap-1">
                <Heading>Contact</Heading>
                <UnderlinedTextLink>
                  <a
                    href={`mailto:${contactEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg te"
                  >
                    {contactEmail}
                  </a>
                </UnderlinedTextLink>
                <UnderlinedTextLink>
                  <a
                    href={`mailto:${mediaEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg"
                  >
                    {mediaEmail}
                  </a>
                </UnderlinedTextLink>
                <div className="flex justify-between w-28 mt-2">
                  <SocialButton
                    href="https://www.linkedin.com/company/jump-trading"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">Go to Jump's LinkedIn</span>
                    <LinkedIn className="h-full p-2 hover:fill-background" />
                  </SocialButton>
                  <SocialButton
                    href="https://twitter.com/jumptrading"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">Go to Jump's Twitter</span>
                    <Twitter className="h-full p-2 hover:fill-background" />
                  </SocialButton>
                </div>
              </div>

              <div className="col-span-1">
                <div className="flex flex-col gap-1">
                  <Heading>Company</Heading>
                  <UnderlinedTextLink>
                    <Link to="/careers/" className="text-lg font-normal">
                      Careers
                    </Link>
                  </UnderlinedTextLink>
                </div>
              </div>

              <div className="flex flex-col gap-1 col-span-1 md:col-span-2 lg:col-span-1 w-full">
                <Heading>Locations</Heading>
                <ul
                  role="list"
                  className="grid grid-cols-2 w-full gap-x-12 gap-y-3
                md:grid-cols-3 md:justify-center lg:justify-items-start"
                >
                  {locations.map((location) => (
                    <li key={location} role="listitem" className="col-span-1">
                      <div className="font-medium text-lg whitespace-nowrap">{location}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-12  text-sm font-normal text-dark-gray">
            <div className="grid gap-x-16 gap-y-4  auto-cols-max  md:grid-cols-[auto_auto_auto_auto_auto] justify-start ">
              <UnderlinedTextLink className="block border-dark-gray">
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </UnderlinedTextLink>
              <UnderlinedTextLink className="block border-dark-gray">
                <a href={`/${mssFilename}`}>Modern Slavery Statement</a>
              </UnderlinedTextLink>

              <UnderlinedTextLink className="block border-dark-gray">
                <Link to="/disclosures/">Disclosures</Link>
              </UnderlinedTextLink>
              <UnderlinedTextLink className="block border-dark-gray">
                <Link to="/terms-of-use/">Terms Of Use</Link>
              </UnderlinedTextLink>
              <UnderlinedTextLink className="block border-dark-gray">
                <Link to="/bug-bounties/">Bug Bounties</Link>
              </UnderlinedTextLink>
            </div>
          </div>

          <div className="my-6 md:flex md:items-center md:justify-between">
            <p className="text-sm text-dark-gray ">
              &copy; {new Date().getFullYear()} Jump Trading, LLC. All rights reserved. Jump Trading
              and the Jump Trading logo are registered trademarks.
            </p>
          </div>
        </CenteredResponsiveContainer>
        {!cookieAgreement && (
          <div className=" mt-3 py-1.5 px-3 bg-light-gray ">
            <div
              className=" max-w-7xl px-4 py-2 mx-auto grid gap-3 grid-cols-2
            lg:grid-cols-[auto_auto_auto] justify-items-center items-center"
            >
              <div className="mx-3 col-span-2 lg:col-span-1">
                This site uses cookies. By continuing to use this site, you are agreeing to our use
                of cookies.
              </div>

              <PrivacyPolicyLink>
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </PrivacyPolicyLink>

              <div className="mx-3">
                <button
                  onClick={cookiesOk}
                  className="bg-medium-gray-1 rounded-lg  items-center justify-center px-4 py-2 "
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        )}
      </footer>
    </div>
  )
}

type LayoutQueryType = {
  site: {
    siteMetadata: {
      siteUrl: string
    }
  }
}
const LayoutQuery = graphql`
  query Layout {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
