import React from 'react'
import { Helmet, HelmetProps } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

// Types
type SEOProps = {
  description?: string
  meta?: Meta
  title?: string
  pathname?: string
  useDefaultImage?: boolean
} & HelmetProps

type Meta = ConcatArray<PropertyMetaObj | NameMetaObj>

type PropertyMetaObj = {
  property: string
  content: string
}

type NameMetaObj = {
  name: string
  content: string
}

type QueryTypes = {
  site: {
    siteMetadata: {
      defaultTitle: string
      defaultDescription: string
      siteUrl: string
      socialImage: string
      socialImageHeight: number
      socialImageWidth: number
      twitterUsername: string
    }
  }
}

export function SEO({
  children,
  description = '',
  meta = [],
  title,
  pathname,
  useDefaultImage = true,
  ...props
}: SEOProps) {
  const { site } = useStaticQuery<QueryTypes>(SEOStaticQuery)

  const defaultTitle = site.siteMetadata?.defaultTitle
  const metaDescription = description || site.siteMetadata.defaultDescription
  let image = undefined
  if (useDefaultImage) {
    image = `${site.siteMetadata.siteUrl}${site.siteMetadata.socialImage}`
  }

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  return (
    <Helmet
      {...props}
      htmlAttributes={{
        lang: 'en',
      }}
      title={title || defaultTitle}
      link={[
        {
          rel: `apple-touch-icon`,
          href: `${site.siteMetadata.siteUrl}/apple-touch-icon.png`,
          sizes: `180x180`,
        },
        {
          rel: `icon`,
          href: `${site.siteMetadata.siteUrl}/favicon-32x32.png`,
          sizes: `32x32`,
          type: `image/png`,
        },
        {
          rel: `icon`,
          href: `${site.siteMetadata.siteUrl}/favicon-16x16.png`,
          sizes: `16x16`,
          type: `image/png`,
        },
        {
          rel: `manifest`,
          href: `${site.siteMetadata.siteUrl}/site.webmanifest`,
        },
        {
          rel: `mask-icon`,
          href: `${site.siteMetadata.siteUrl}/safari-pinned-tab.svg`,
          color: `#EE322F`,
        },

        canonical
          ? {
              rel: 'canonical',
              href: canonical,
            }
          : {},
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        canonical
          ? {
              property: `og:url`,
              content: canonical,
            }
          : {},
        {
          name: `twitter:site`,
          content: site.siteMetadata?.twitterUsername || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `msapplication-TileColor`,
          content: `#f8f8f8`,
        },
        {
          name: `theme-color`,
          content: `#e7e7e7`,
        },
      ]
        .concat(
          image
            ? [
                {
                  property: 'og:image',
                  content: image,
                },
                {
                  property: 'og:image:secure_url',
                  content: image,
                },
                {
                  property: `og:image:type`,
                  content: `image/png`,
                },
                {
                  property: `og:image:width`,
                  content: site.siteMetadata.socialImageWidth,
                },
                {
                  property: `og:image:height`,
                  content: site.siteMetadata.socialImageHeight,
                },
                {
                  property: `og:image:alt`,
                  content: `Jump Trading logo`,
                },
                {
                  name: `twitter:image`,
                  content: image,
                },
                {
                  name: `twitter:image:alt`,
                  content: `Jump Trading logo`,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
                {
                  name: `twitter:creator`,
                  content: site.siteMetadata?.twitterUsername || ``,
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ],
        )
        .concat(meta)}
    >
      {children}
    </Helmet>
  )
}

// Queries
const SEOStaticQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        socialImage
        socialImageHeight
        socialImageWidth
        twitterUsername
      }
    }
  }
`
